<template lang="pug">
.hours-available
  .hours-available-title(v-if="showTitle")
    strong {{ $t('layout.header.contacts.availableHours') }}
  .hours-available-text(v-for="(option, index) in options", :key="index")
    strong(v-html="option.days")
    span(v-if="option.customText") {{ option.customText }}
    span(v-else) {{ `${option.from} – ${option.to} ET` }}
</template>

<script>
export default {
  name: 'HoursAvailableComponent',

  props: {
    showTitle: {
      type: Boolean,
      default: true
    },

    options: {
      type: Array,
      default: () => [
        {
          days: 'Mon – Fri:',
          from: '10:00am',
          to: '6:00pm'
        },
        {
          days: 'Sat & Sun:',
          customText: 'Variable - Try Us!',
          from: null,
          to: null
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.hours-available
  line-height: 20px
  &-title
    margin-bottom: 8px
  &-text
    &:first-child
      margin-top: 15px
    strong
      margin-right: 5px
</style>
