<template lang="pug">
.live-chat-view
  hours-available-component(:options="options")
  button.button.primary-color.light(@click="openChat") {{ $t('layout.header.contacts.chat') }}
</template>

<script>
import { mapState } from 'vuex'
import HoursAvailableComponent from './hoursAvailable'
export default {
  name: 'LiveChatViewComponent',

  components: { HoursAvailableComponent },

  computed: {
    ...mapState({
      workingHours: (state) => state.app.workingHours
    }),

    options() {
      return this.workingHours.liveChat
    }
  },

  methods: {
    openChat() {
      if (window.userlikeStartChat) window.userlikeStartChat()
    }
  }
}
</script>

<style lang="sass" scoped>
.live-chat-view
  line-height: 20px
</style>
